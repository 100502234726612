const info = [
    {
        id: 0,
        pic: 'meeting.png',
        name: 'О компании',
        descrip: 'Наша философия, этика и слоганы под которыми работает АРГУС. Подход, методы, цели, а также то, что о нас говорят довольные клиенты.',
        href: '/o-kompanii'
    },
    {
        id: 1,
        pic: 'human-resources.png',
        name: 'Мы в лицах',
        descrip: 'Познакомьтесь с нашим потрясающим коллективом. Мы профессионалы своего дела, с многолетним опытом за плечами.',
        href: '/litsa'
    },
    {
        id: 2,
        pic: 'marketing.png',
        name: 'Блог',
        descrip: 'Транслируем все самые важные и полезные новости индустрии, знаем какие статьи любят читать наши клиенты и пишем специально для них.',
        href: '/blog'
    },
    {
        id: 3,
        pic: 'gift-box.png',
        name: 'Акции',
        descrip: 'Дарим вам скидки и самые "вкусные" предложения. Ещё у нас полно подарков, которыми мы готовы постоянно делиться и делаем это безвозмездно.',
        href: '/aktsii'
    },
    {
        id: 4,
        pic: 'certification.png',
        name: 'Сертификаты',
        descrip: 'Лицензии, сертификаты, разрешительные документы. Все наши бумаги в порядке. Пожалуйста, открывайте, скачивайте.',
        href: '/certificates'
    },
    {
        id: 5,
        pic: 'viral-marketing.png',
        name: 'Отзывы',
        descrip: 'С кем работаем, сотрудничаем, кто рекомендует АРГУС и почему. У нас много постоянных клиентов и партнёров. Не верите? Почитайте, посмотрите.',
        href: '/otzyvy'
    },
    {
        id: 6,
        pic: 'communicate.png',
        name: 'Контакты',
        descrip: 'Как связаться с нами и добраться до уютного офиса АРГУС. А также все наши соцсети, где вы сможете узнать о жизни компании, посмотреть фото и пообщаться с нами.',
        href: '/kontakty'
    }
];

export default info;