const sro = [
    {
      name: 'СРО Строителей',
      copy: 0
    },
    {
      name: 'СРО Проектировщиков',
      copy: 0
    },
    {
      name: 'СРО Изыскателей',
      copy: 0
    }
]

export default sro;