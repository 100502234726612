export default {
    "main" : {
        "title" : "Добро пожаловать в Аргус-Эко", 
        "meta" : "АРГУС - Поддержка бизнеса. Регистрируйте ЭТЛ “под ключ”, вступайте в СРО, проходите обучение в нашем центре, получите лицензию МЧС + всё оборудование в аренду",
        "metakeys" : "регистрация, электролаборатория, ростехнадзор, москва, московская область, этл, получить, свидетельство, под ключ, сро, мчс, охрана труда, электробезопасность, исо"
    },
    "legal-services" : {
        "title" : "Правовая поддержка", 
        "meta" : "Регистрируем ИП, ООО, открываем ОП, ведём юридическое сопровождение и кадровый учёт",
        "metakeys" : "регистрация ип, регистрация ооо, регистрация оп, регистрация обособленного подразделения, юридическая поддержка, юридическое сопровождение, кадровый учёт"
    },
    "sro" : {
        "title" : "Вступление в надёжное СРО",
        "meta" : "Вступление в надёжное СРО во всех регионах РФ всего за 2 дня. Подбор лучшего СРО в Вашем городе. Подготовка и оформление пакета документов. Внесение в Госреестр за 2 рабочих дня. Бессрочное сопровождение. 100% гарантия результата.",
        "metakeys" : "сро, вступление в сро, сро строителей, сро проектировщиков, сро изыскателей, сро энергетиков, нострой, ноприз"
    },
    "licenses" : {
        "title" : "Лицензии ЭТЛ и МЧС", 
        "meta" : "Содействуем в получении лицензий для Вашего бизнеса в режиме «под ключ»",
        "metakeys" : "регистрация, электролаборатория, ростехнадзор, мчс, лицензия, под ключ"
    },
    "certifications" : {
        "title" : "Сертификаты ИСО, ЖКХ, СП", 
        "meta" : "Помогаем получить сертификаты ИСО, ЖКХ и соответствия продукции",
        "metakeys" : "сертификат, исо, жкх, соответствие продукции, сп, под ключ"
    },
    "work-safety" : {
        "title" : "Охрана труда", 
        "meta" : "Наш учебный центр открыт для всех, кому необходимо получить профессиональные знания и пройти проверку в различных инстанциях",
        "metakeys" : "охрана труда, удостоверение, обучение пожарному минимуму, дистанционно, очно"
    },
    "registratsiya-elektrolaboratorii" : {
        "title" : "Получите свидетельство о регистрации ЭТЛ за 6 недель", 
        "meta" : "В Москве и МО, обучение и аттестация персонала, 100% регистрация в Ростехнадзоре, сопровождение от и до, легальное свидетельство, регистриуем лаборатории уже 21 год",
        "metakeys" : "регистрация, электролаборатория, ростехнадзор, москва, московская область, этл"
    },
    "litsenziya-mchs" : {
        "title" : "Оформим лицензию МЧС «под ключ»", 
        "meta" : "Помогаем оформить лицензию МЧС в самые сжатые сроки и без лишней бюрократии",
        "metakeys" : "мчс, лицензия, под ключ"
    }
}